import { PALMER, READER } from '../constants/common/search/platform';

export const getPlatformFilters = (isPalmerSupported: boolean) => {
  if (isPalmerSupported) {
    return [
      {
        field: 'platform',
        values: [READER, PALMER],
      },
    ];
  } else {
    return [
      {
        field: 'platform',
        values: [READER],
      },
    ];
  }
};
