// Mui Components
import { useCallback, useEffect, useState } from 'react';
import { useBeforeUnload, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Typography } from '@mui/material';

// Redux
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getDisciplines } from '../../../app/thunks/disciplineThunk';
import { getUser } from '../../../app/thunks/userThunk';
import { searchOffers } from '../../../app/thunks/searchThunk';
import { getSuggestions } from '../../../app/thunks/suggestionsThunk';
import { setStateValue } from '../../../app/stateSlices/disciplineSlice';
import {
  setNoResultFound,
  setSelectedInputValue,
  setSearchResultLabel,
  setSearchQuery,
  setSearchFrom,
  setIsSearchResultArr,
  setSearchResultsArr,
  setIsReadMoreOpenArray,
} from '../../../app/stateSlices/offerSlice';
import { setSortOptions, setSortBy } from '../../../app/stateSlices/sortSlice';
import { setSuggestions } from '../../../app/stateSlices/suggestionsSlice';

import storage from 'redux-persist/lib/storage/session';

// Components
import { SearchComponent } from '../component/SearchComponent';
import DisciplineComponent from '../../Discipline/DisciplineComponent';
import CardComponent from '../../ListVew/CardComponent';
import FilterComponent from '../../filter/FilterComponent';
import SortByComponent from '../../SortBy/SortByComponent';
import NoResultFoundComponent from '../../NoResultFound/NoResultFoundComponent';
import NoDisciplineSelectedImage from '../../../resources/images/NoDisciplineSelected.svg';
import {
  isContext,
  handleW3PIContextKeys,
  setPlatform,
  buildPendoData,
  setFilterPlatform,
} from './functionDefinition/SearchContainerFunction';
import { SearchPreLoader } from '../../skeleton/SearchPreLoader';
import { LoadMore } from '../../LoadMore/LoadMore';

// Types
import { SortByList } from '../../type/ISortByComponent';
import { RequestBody, initRequestBody } from '../../../app/types/search';
import {
  SuggestionRequestBody,
  initSuggestionReqBody,
} from '../../../app/types/suggestions';

// Constants
import {
  DISCIPLINE_TITLE,
  MY_ACCOUNT,
  W3PI,
} from '../../../constants/common/header';

// import { sortByList } from '../../SortBy/SortByList';

// Utils
import { useQuery } from '../../../util/route';

// Style Definitions
import {
  container,
  filterComponentGrid,
  noDisciplineSelectedImg,
  noDisciplineSelectedImgContainer,
  searchResultLabelGrid,
  sortComponentGrid,
} from './styleDefinitions/SearchContainerStyles';
import { disciplineLabel } from '../../Discipline/styleDefinitions/DisciplineLabelStyles';
import {
  disciplineBox,
  disciplineContainer,
} from '../../Discipline/styleDefinitions/DisciplineComponentStyles';
import {
  searchBox,
  searchContainer,
} from '../component/styleDefinitions/SearchComponentStyles';
import {
  sortComponentBox,
  sortContainer,
} from '../../SortBy/styleDefinitions/SortByComponentStyles';
import { searchResultsLabel } from '../component/styleDefinitions/SearchResultsLabelStyles';
import {
  cardComponentBox,
  cardComponentContainer,
} from '../../ListVew/styleDefinitions/CardComponentStyles';
import { listViewContainer } from '../../ListVew/styleDefinitions/ListViewStyles';

import env from '../../../common/env';
import { getUserSession } from '../../../app/thunks/userSessionThunk';
import { filterByList } from '../../filter/filterByList';
import { setFilterBy } from '../../../app/stateSlices/filterSlice';
import { userSessionCompleted } from '../../../app/stateSlices/userSessionSlice';
import { PALMER, READER } from '../../../constants/common/search/platform';

export const SearchContainer = () => {
  const [navigateToCB, setNavigateToCB] = useState<boolean>(false);
  const [selectedIsbn, setSelectedIsbn] = useState<string>('');
  const [isLoadSearchResults, setIsLoadSearchResults] =
    useState<boolean>(false);

  const userProfileKey = useAppSelector((state) => state.user.user.userKey);
  const discipline = useAppSelector(
    (state) => state.disciplines.selectedDiscipline
  );
  const visible = useAppSelector((state) => state.disciplines.visible);
  const noResultFound = useAppSelector((state) => state.offer.noResultFound);
  const isSearchResultArr = useAppSelector(
    (state) => state.offer.isSearchResultArr
  );
  const selectedInputValue = useAppSelector(
    (state) => state.offer.selectedInputValue
  );
  const searchResultLabel = useAppSelector(
    (state) => state.offer.searchResultLabel
  );
  const sortOptions = useAppSelector((state) => state.sort.sortOptions);
  const sortBy = useAppSelector((state) => state.sort.sortBy);

  const launchContext = useAppSelector((state) => state.user.launchContext);
  const disciplines = useAppSelector((state) => state.disciplines.values);
  const searchResults = useAppSelector((state) => state.offer.offers);
  const isUserValidated = useAppSelector((state) => state.user.userValidated);
  const searchQuery = useAppSelector((state) => state.offer.searchQuery);
  const isLoadingOffer = useAppSelector((state) => state.offer.loading);
  const searchFrom = useAppSelector((state) => state.offer.searchFrom);
  const totalSearchResults = useAppSelector(
    (state) => state.offer.totalSearchResults
  );
  const searchResultsArr = useAppSelector(
    (state) => state.offer.searchResultsArr
  );
  const isReadMoreOpen = useAppSelector((state) => state.offer.isReadMoreOpen);

  const productPairingComplete = useAppSelector(
    (state) => state.productPair.loadingComplete
  );
  const isProductPaired = useAppSelector(
    (state) => state.productPair.isProductPaired
  );

  const ltiLaunchContextKey = useAppSelector(
    (state) => state.user.W3PILtiLaunchId
  );

  const isPalmerSupported = useAppSelector(
    (state) => state.user.IsPalmerSupported
  );

  const filterBy = useAppSelector((state) => state.filter.filterBy);

  const userSession = useAppSelector((state) => state.userSession.userSession);

  const query = useQuery();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useBeforeUnload(() => {
    if (!navigateToCB) {
      dispatch({ type: 'RESET_STATE' });
    }
  });

  const pendoData = useCallback(() => {
    return buildPendoData(userSession);
  }, [userSession]);

  useEffect(() => {
    if (navigateToCB) {
      window.location.href = `${env.COURSE_BUILDER_URL}?userProfileKey=${userProfileKey}&isbn=${selectedIsbn}#/remix-create-course`;
      setNavigateToCB(false);
    }
  });

  useEffect(() => {
    if (productPairingComplete) {
      if (isProductPaired) {
        dispatch(userSessionCompleted(false));
        navigate('/pairingSuccess');
      } else {
        navigate('/pairingFailure');
      }
    }
  }, [productPairingComplete]);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const isPalmerSupportedQuery = query.get('isPalmerSupported');
      const isPalmerEnable =
        isPalmerSupportedQuery !== null
          ? isPalmerSupportedQuery === 'true'
          : (((await storage.getItem('IsPalmerSupported')) ===
              'true') as boolean);
      if (isUserValidated) {
        dispatch(getDisciplines(isPalmerEnable));
      }
    })();
  }, [dispatch, isUserValidated]);

  useEffect(() => {
    if (
      (launchContext === W3PI && ltiLaunchContextKey) ||
      launchContext === MY_ACCOUNT
    ) {
      fetchUserSession();
    }
  }, [ltiLaunchContextKey]);

  const fetchUserSession = useCallback(() => {
    if (env.PENDO_ENABLE) {
      dispatch(getUserSession(ltiLaunchContextKey));
    }
  }, [ltiLaunchContextKey]);

  useEffect(() => {
    (async () => {
      if (isUserValidated && !launchContext) {
        const queryParam = query.get('context');
        const context =
          queryParam !== null
            ? queryParam
            : await storage.getItem('launchContext');
        dispatch(isContext(context));
      } else {
        if (launchContext === W3PI) {
          const learningContextKeyQuery = query.get('learningContextKey');
          const messageTypeQuery = query.get('messageType');
          const ltiLaunchIdQuery = query.get('ltiLaunchId');
          const integrationKeyQuery = query.get('integrationKey');
          const sourceContextKeyQuery = query.get('sourceContextKey');
          const isPalmerSupportedQuery = query.get('isPalmerSupported');
          const learningContextKey =
            learningContextKeyQuery !== null
              ? learningContextKeyQuery
              : await storage.getItem('W3PILearningContextKey');
          const ltiLaunchId =
            ltiLaunchIdQuery !== null
              ? ltiLaunchIdQuery
              : await storage.getItem('W3PILtiLaunchId');
          const integrationKey =
            integrationKeyQuery !== null
              ? integrationKeyQuery
              : await storage.getItem('W3PIIntegrationKey');
          const sourceContextKey =
            sourceContextKeyQuery !== null
              ? sourceContextKeyQuery
              : await storage.getItem('W3PISourceContextKey');
          const messageType =
            messageTypeQuery !== null
              ? messageTypeQuery
              : await storage.getItem('W3PIMessageType');
          const isPalmerSupported =
            isPalmerSupportedQuery !== null
              ? isPalmerSupportedQuery === 'true'
              : (((await storage.getItem('IsPalmerSupported')) ===
                  'true') as boolean);

          const W3PIContext = {
            learningContextKey,
            messageType,
            ltiLaunchId,
            integrationKey,
            sourceContextKey,
            isPalmerSupported,
          };

          dispatch(handleW3PIContextKeys(W3PIContext));
        } else if (launchContext !== '') {
          storage.removeItem('W3PILearningContextKey');
          storage.removeItem('W3PIMessageType');
          storage.removeItem('W3PILtiLaunchId');
          storage.removeItem('W3PIIntegrationKey');
          storage.removeItem('W3PISourceContextKey');
          storage.removeItem('IsPalmerSupported');
        }
      }
    })();
  }, [dispatch, query, isUserValidated, launchContext]);

  useEffect(() => {
    searchResults?.length > 0
      ? dispatch(setNoResultFound(false))
      : dispatch(setNoResultFound(true));

    let resultCount = totalSearchResults;
    if (searchResults?.length == 0) {
      resultCount = 0;
    }
    if (discipline !== '') {
      if (searchQuery === '') {
        setRelevanceState(sortOptions, true);
        dispatch(
          setSearchResultLabel(`(${resultCount}) ${discipline} Products`)
        );
      } else {
        dispatch(
          setSearchResultLabel(`(${resultCount}) results for "${searchQuery}"`)
        );
        if (resultCount > 0) {
          setRelevanceState(sortOptions, false);
        } else {
          dispatch(setSortBy('TITLE-ASC'));
        }
      }
    }
  }, [discipline, searchResults]);

  const setRelevanceState = (
    sortOptions: Array<SortByList>,
    shouldDisable: boolean
  ): void => {
    const relevanceOption = sortOptions.find(
      (option) => option.value === 'RELEVANCE-ASC'
    );
    if (relevanceOption?.disabled !== shouldDisable) {
      const updatedSortOptions = sortOptions.map((sortOption: SortByList) => {
        if (sortOption.value === 'RELEVANCE-ASC') {
          return {
            ...sortOption,
            disabled: shouldDisable,
          };
        }
        return sortOption;
      });
      dispatch(setSortOptions(updatedSortOptions));
    }
  };

  const handleDiscipline = (discipline: string) => {
    dispatch(setFilterBy(filterByList[isPalmerSupported ? 0 : 2]));
    dispatch(setSearchFrom(0));
    dispatch(setStateValue({ field: 'selectedDiscipline', value: discipline }));
    dispatch(setSelectedInputValue(''));
    dispatch(setSortBy('RELEASED_DATE-DESC'));
    setIsLoadSearchResults(true);
  };

  const handleSearch = (searchKeyword: string, filterByParam: string) => {
    dispatch(setSearchFrom(0));
    const sortObject: Partial<SortByList> = {
      sortField: 'RELEVANCE',
      order: 'ASC' as 'ASC' | 'DESC',
    };
    if (searchKeyword === '') {
      sortObject.sortField = 'RELEASED_DATE';
      sortObject.order = 'DESC' as 'ASC' | 'DESC';
      dispatch(setSortBy('RELEASED_DATE-DESC'));
    } else {
      dispatch(setSortBy('RELEVANCE-ASC'));
    }
    dispatch(setSearchQuery(searchKeyword));
    performSearch(searchKeyword, sortObject, 0, filterByParam);
    setIsLoadSearchResults(true);
  };

  const handleSort = (sortOptions: SortByList) => {
    dispatch(setSearchFrom(0));
    if (selectedInputValue !== searchQuery) {
      dispatch(setSelectedInputValue(searchQuery));
    }
    performSearch(searchQuery, sortOptions, 0, filterBy);
    setIsLoadSearchResults(true);
  };

  const handleFilter = (filterByValue: string) => {
    dispatch(setSearchFrom(0));

    const sortByArray = sortBy.split('-');
    const sortOptions: Partial<SortByList> = {
      sortField: sortByArray[0],
      order: sortByArray[1] as 'ASC' | 'DESC',
    };
    performSearch(searchQuery, sortOptions, 0, filterByValue);
    setIsLoadSearchResults(true);
  };

  const performSearch = (
    searchKeyword: string,
    sortObject: Partial<SortByList>,
    searchFrom: number,
    filterByValue: string
  ) => {
    const requestBodyParameters: RequestBody = {
      searchQuery: searchKeyword,
      launchContext,
      discipline,
      from: searchFrom,
      sort: sortObject.sortField as string,
      sortOrder: sortObject.order as string,
    };

    if (filterByValue === filterByList[0]) {
      requestBodyParameters.filters = [
        {
          field: 'platform',
          values: [READER, PALMER],
        },
      ];
    } else {
      requestBodyParameters.filters = [
        {
          field: 'platform',
          values: [setFilterPlatform(filterByValue)],
        },
      ];
    }
    const requestBody = initRequestBody(requestBodyParameters);
    dispatch(searchOffers(requestBody));
    dispatch(setSearchQuery(searchKeyword));
  };

  useEffect(() => {
    const searchResultsLength = searchResults?.length;
    dispatch(setIsSearchResultArr(searchResultsLength > 0));
    if (isLoadSearchResults) {
      if (searchFrom !== 0) {
        dispatch(setSearchResultsArr([...searchResultsArr, ...searchResults]));
        dispatch(
          setIsReadMoreOpenArray([
            ...isReadMoreOpen,
            ...new Array(searchResultsLength).fill('closed'),
          ])
        );
      } else {
        dispatch(setSearchResultsArr(searchResults));
        dispatch(
          setIsReadMoreOpenArray(new Array(searchResultsLength).fill('closed'))
        );
      }
    }
  }, [searchResults]);

  const isLoadMoreVisible = () => {
    if (
      totalSearchResults &&
      searchResultsArr &&
      totalSearchResults > searchResultsArr.length
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (discipline !== '' && searchFrom !== 0 && isLoadSearchResults) {
      const sortByArray = sortBy.split('-');
      const partialSortOptions: Partial<SortByList> = {
        sortField: sortByArray[0],
        order: sortByArray[1] as 'ASC' | 'DESC',
      };
      performSearch(searchQuery, partialSortOptions, searchFrom, filterBy);
    }
  }, [searchFrom]);

  useEffect(() => {
    if (selectedInputValue.length > 2) {
      const requestBodyParameters: SuggestionRequestBody = {
        inputQuery: selectedInputValue,
        launchContext,
        discipline,
      };
      if (!isPalmerSupported) {
        requestBodyParameters.filters = [
          {
            field: 'platform',
            values: [READER],
          },
        ];
      } else {
        if (filterBy !== filterByList[0]) {
          requestBodyParameters.filters = [
            {
              field: 'platform',
              values: [setFilterPlatform(filterBy)],
            },
          ];
        }
      }
      const requestBody = initSuggestionReqBody(requestBodyParameters);
      dispatch(getSuggestions(requestBody));
    } else {
      dispatch(setSuggestions([]));
    }
  }, [discipline, dispatch, launchContext, selectedInputValue, filterBy]);

  useEffect(() => {
    if (env.PENDO_ENABLE && pendoData().visitor.id) {
      try {
        (window as any).pendo && (window as any).pendo.initialize(pendoData());
      } catch (err) {
        console.log('pendo init error: ', err);
      }
    }
  }, [pendoData]);

  return (
    <Grid container sx={container}>
      <Grid xs={12} sm={6} md={6} lg={4}>
        <Box sx={disciplineContainer}>
          <Box>
            <Typography sx={disciplineLabel} variant="h3">
              {DISCIPLINE_TITLE}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid xs={12} sm={6} md={6} lg={8}>
        <Box sx={disciplineBox}>
          <DisciplineComponent
            options={disciplines}
            handleDiscipline={handleDiscipline}
            discipline={discipline}
          />
        </Box>
      </Grid>
      {visible === false ? (
        <Grid xs={12} sm={6} md={6} lg={6}>
          <Box sx={noDisciplineSelectedImgContainer}>
            <img
              src={NoDisciplineSelectedImage}
              alt="No discipline selected svg"
              style={noDisciplineSelectedImg}
              aria-hidden="true"
              id="NoDisciplineSelected"
            />
          </Box>
        </Grid>
      ) : (
        <>
          <Grid container columns={50} sx={searchContainer}>
            <Box id="search_box" sx={searchBox}>
              <SearchComponent
                handleSearch={handleSearch}
                noResultFound={noResultFound}
              />
            </Box>
            {isLoadingOffer && searchFrom === 0 ? (
              <SearchPreLoader data-testid={'pre-loaders-test'} />
            ) : (
              <>
                <Box sx={sortContainer}>
                  <Grid sx={searchResultLabelGrid}>
                    <Box>
                      <Typography sx={searchResultsLabel}>
                        {searchResultLabel}
                      </Typography>
                    </Box>
                  </Grid>
                  {isPalmerSupported && ( // disable FilterComponent unless palmer supported
                    <Grid sx={filterComponentGrid}>
                      <Box sx={sortComponentBox}>
                        <FilterComponent handleFilter={handleFilter} />
                      </Box>
                    </Grid>
                  )}
                  <Grid sx={sortComponentGrid}>
                    <Box sx={sortComponentBox}>
                      <SortByComponent
                        sortBy={sortBy}
                        options={sortOptions}
                        handleSort={handleSort}
                        noResultFound={noResultFound}
                      />
                    </Box>
                  </Grid>
                </Box>
                <Box sx={listViewContainer}>
                  {isSearchResultArr ? (
                    searchResultsArr.map((result, index) => (
                      <Box key={index} sx={cardComponentBox}>
                        <Box key={index} sx={cardComponentContainer}>
                          <CardComponent
                            key={result.productId}
                            title={result.title}
                            authors={result.authors}
                            cover={result.cover}
                            description={result.description}
                            platform={setPlatform(result.platform)}
                            isbn={result.offerIsbn}
                            bookId={result.baseProductIsbn}
                            navigateToCB={navigateToCB}
                            setNavigateToCB={setNavigateToCB}
                            setSelectedIsbn={setSelectedIsbn}
                            productIndex={index}
                            eBooks={result.eBooks}
                          />
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Box>
                      <NoResultFoundComponent />
                    </Box>
                  )}
                </Box>
                {isLoadMoreVisible() ? (
                  <LoadMore setIsLoadSearchResults={setIsLoadSearchResults} />
                ) : (
                  <></>
                )}
              </>
            )}
          </Grid>
          <Grid container sx={{ width: '20%' }}>
            <Grid xs={12} md={12} lg={12}>
              {/* Following Item should be replaced with filters component */}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
